//  BASIC CONFIG //
@font-face {
  font-family: "Futura PT";
  src: url("../fonts/FuturaPT-Light.woff2") format("woff2"),
    url("../fonts/FuturaPT-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Futura PT";
  src: url("../fonts/FuturaPT-Book.woff2") format("woff2"),
    url("../fonts/FuturaPT-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Futura PT";
  src: url("../fonts/FuturaPT-Medium.woff2") format("woff2"),
    url("../fonts/FuturaPT-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "De Valencia";
  src: url("../fonts/DeValencia.woff2") format("woff2"),
    url("../fonts/DeValencia.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}
*,
body,
html {
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}
html {
  height: 100%;
  font-size: 10px;
}
@media only screen and (min-width:1921px) {html {font-size: 12px;}}
@media only screen and (min-width:2049px) {html {font-size: 14px;}}
body {
  min-height: 100%;
  width: 100%;
  min-width: 320px;
  position: relative;
  font: 400 2rem / 2.6rem "Futura PT", sans-serif;
  color: #000;
}
//  END //

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.global-wrapper {
  position: relative;
}
.wrapper {
  width: 100%;
  max-width: 114.2rem;
  margin: 0 auto;
  padding: 0 1.6rem;
}
h1,h2,h3,h4,p,ul,ol {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: #000;
}
.global-wrapper h1,
.global-wrapper h2 {
  font-weight: 300;
  font-size: 4.8rem;
  line-height: 6.2rem;
}
h3 {
  font-weight: 300;
  font-size: 3.6rem;
  line-height: 4.6rem;
}
.overflow {
  overflow: hidden;
}
.btn {
  width: 100%;
  max-width: 35rem;
  padding: 2.5rem 3.4rem;
  border: 1px solid #fff;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  transition: all .3s linear;
  &:hover {
    border-color: #EA4C89;
    background-color: #EA4C89;
  }
}
.inp {
  width: 100%;
  padding: 2.8rem 2rem 2.7rem 2rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.15rem;
  border: none;
  border: 1px solid #000000;
  background-color: #fff;
  &::placeholder {
      color: #696969;
      text-transform: uppercase;
  }
}

// BREADCRUMBS //
.bredacrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  * {
    position: relative;
    font-size: 1.4rem;
    line-height: 3rem;
    color: #A0A0A0;
    transition: all .3s linear;
    &:not(:last-child) {
      padding-right: 1.6rem;
      &:hover {
        color: #000;
      }
      &::after {
        content: '/';
        position: absolute;
        right: .35rem;
        top: 50%;
        transform: translateY(-50%);
        color: #A0A0A0;
      }
    }
  }
}
// END //

// PAGINATION //
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  * {
    width: 5rem;
    height: 5rem;
    background: #FFFFFF;
    border: 1px solid #000000;
    padding: 1.6rem;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-align: center;
    transition: all .3s linear;
    &:hover,
    &.current {
      border-color: #EA4C89;
      background-color: #EA4C89;
      color: #fff;
    }
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
// END //

// INFOBANER //
.infobaner {
  position: relative;
  width: 100%;
  min-height: 66.8rem;
  padding: 5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &__bg-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    &.catalog__infobaner-bg {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
  &__info {
    position: relative;
    width: 100%;
    max-width: 111rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 9.5rem;
    background-color: rgba(0, 0, 0, .5);
    .bg__line1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 151.5rem;
      height: 0.1rem;
      background-color: #EA4C89;
    }
    .bg__line2 {
      position: absolute;
      top: -5.9rem;
      right: 0;
      width: 0.1rem;
      height: 61.8rem;
      background-color: #EA4C89;
    }
    .round__txt {
      position: absolute;
      width: 15.4rem;
      height: 15.4rem;
      top: -2.7rem;
      right: -7.7rem;
    }
  }
  &__info-content {
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;
    * {
      width: 100%;
      margin-bottom: 2rem;
    }
    img {
      width: 5rem;
    }
    h3 {
      max-width: 54rem;
    }
  }
}
// END //

// PRODUCT SLIDER //
.product__slider {
  
  .slick-prev,
  .slick-next {
    width: 3rem;
    height: 10rem;
    background-color: #FFF;
    box-shadow: .1rem .1rem 1rem rgba(0, 0, 0, 0.05);
    border-radius: 5rem;
    transition: all .3s linear;
    &:hover {
      box-shadow: .1rem .1rem 1rem rgba(0, 0, 0, 0.2);
    }
    &::after {
      content: '';
      width: 1rem;
      height: 1rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url('../img/slider-arr.svg') center / contain no-repeat;
    }
  }
  .slick-prev {
    left: -6rem;
    &::after {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
  .slick-next {
    right: -4rem;
  }
}
// END //

// PRODUCT //
.product {
  width: 100%;
  max-width: 25.5rem;
  margin: 0 auto;
  &__img-wrap {
    position: relative;
    width: 100%;
    height: 34rem;
    margin-bottom: 2rem;
    &:hover {
      img {
        transform: rotateY(180deg);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
    .product__discount {
      width: 6rem;
      height: 6rem;
      position: absolute;
      z-index: 1;
      left: -2rem;
      top: -2rem;
      background: url('../img/product-discount.svg') center / contain no-repeat;
      padding: 1.2rem 1.3rem;
      * {
        font-weight: bold;
        font-size: 2rem;
        line-height: 2.6rem;
        color: #FFFFFF;
      }
    }
    .product__delete {
      position: absolute;
      right: 1rem;
      top: 1rem;
      padding: 0;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      transition: all .3s linear;
      &:hover {
        background-color: #EA4C89;
        svg {
          path {
            fill: #fff;
          }
        }
      }
      svg {
        width: 1.8rem;
        path {
          transition: all .3s linear;
        }
      }
    }
  }
  &__sizes-like {
    display: flex;
    align-items: flex-start;
    //justify-content: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;
    .size {
      width: 100%;
      max-width: 21rem;
      min-height: 3.2rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #696969;
      * {
        &:not(:nth-child(7n)) {
          margin-right: 1rem;
        }
        &.active__size {
          color: #000;
        }
      }
    }
    .icons {
      &.booking {
        margin-left: auto;
        margin-right: 1.2rem;
      }
      &:hover .booking__icon,
      input:checked + .booking__icon {
        background-image: url('../img/product-bookmark-checked.svg');
      }
      &:hover .like__icon,
      input:checked + .like__icon {
        background-image: url('../img/product-like-checked.svg');
      }
      input {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
      }
      div {
        width: 17px;
        height: 15px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: all .1s linear;
        cursor: pointer;
        &.booking__icon {
          background-image: url('../img/product-bookmark.svg');
        }
        &.like__icon {
          background-image: url('../img/product-like.svg');
        }
      }
    }
  }
  &__reserve {
    &-time {
      display: block;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: #A0A0A0;
      margin-bottom: 1rem;
    }
    &-charact {
      border-top: 1px solid #E5E5E5;
      border-bottom: 1px solid #E5E5E5;
      margin-bottom: 1.5rem;
      .charact__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
        &:not(:last-child) {
          border-bottom: 1px solid #E5E5E5;
        }
        &-color {
          display: flex;
          align-items: center;
        }
        p {
          font-size: 1.4rem;
          line-height: 1.8rem;
          text-transform: uppercase;
          &:nth-child(even) {
            width: 7.5rem;
            text-align: center;
          }
          & > span {
            margin-right: .5rem;
            width: .6rem;
            height: .6rem;
            border-radius: 50%;
          }
        }
      }
    }
  }
  &__colors {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    div {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: .5rem;
      }
      span {
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }
  }
  &__dropdowns {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .size__dropdown {
      max-width: 12.3rem;
      margin-right: 0;
      &-value {
        text-align: left;
      }
    }
  }
  .cart__counter {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1.5rem;
    input {
      width: 100%;
    }
  }
  &__desc {
    width: 100%;
    min-height: 7.8rem;
    margin-bottom: 1rem;
  }
  &__price {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    * {
      font-size: 1.8rem;
      line-height: 2.3rem;
    }
    .old-price {
      font-size: 1.6rem;
      line-height: 2.1rem;
      color: #C4C4C4;
      text-decoration: line-through;
      margin-left: 1rem;
    }
    .wholesale-price {
      color: #EA4C89;
      margin-left: auto;
    }
    .reserve__price {
      &:last-child {
        margin-left: auto;
      }
      span {
        display: block;
        &:first-child {
          margin-bottom: .5rem;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.5rem;
          color: #A0A0A0;
        }
        &:last-child {
          font-size: 1.8rem;
          line-height: 2.3rem;
          letter-spacing: 0.15rem;
          text-transform: uppercase;
        }
      }
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      height: 5rem;
      max-width: 12.3rem;
      padding: 1.4rem 1rem;
    }
  }
  &__booking {
    border-color: #000;
    color: #000;
    &:hover {
      color: #fff;
    }
  }
  &__buy {
    display: block;
    max-width: 100%;
    padding: 1.5rem 3.5rem;
    background-color: #000;
    border: none;
    &:hover {
      background-color: #EA4C89;
    }
  }
}
// END //


// FANCYBOX //
.fancybox-close-small:focus {
  outline: none!important;
}
// END //